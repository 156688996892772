import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"

export default function competenceTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <>
    <SEO title={frontmatter.title} />
    <div className="min-h-screen bg-dark-bleu flex text-white">
      <div className="m-auto max-w-lg">
        <div className="w-48 object-contain text-center m-auto">
          <Img fluid={frontmatter.imageOnPage.childImageSharp.fluid} />
          </div>
        <h1 className="text-5xl text-center">{frontmatter.title}</h1>
        <div
          className="leading-snug my-2 mx-6"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </div>
    </>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        imageOnPage {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
        path
        title
      }
    }
  }
`